<script setup lang="ts">
import MarinadeLandingReward from "~/components/MarinadeLanding/MarinadeLandingReward.vue";
import type { HomePageCampaignDto } from "~~/_api_generated_";

const campaign = ref<HomePageCampaignDto>();
const loading = ref(true);

function fetchData() {
  loading.value = true;
  apiService
    .getMarinadeLanding()
    .then((response) => {
      campaign.value = response.data?.featuredCampaigns[0] ?? {};
    })
    .catch((e) => {
      errorMessage(e);
    })
    .finally(() => {
      loading.value = false;
    });
}

fetchData();
</script>

<template>
  <div class="container-800 mt-8 lg:mt-14 lg:text-center font-semibold">
    <div class="text-4xl lg:text-6xl mb-8">
      Join the Marinade<br />Ambassador Program
    </div>
    <div class="text-2xl mb-8 w-3/4 lg:w-full">
      Earn rewards for your posts on X.<br class="hidden lg:block" />
      Create your ambassador account on Evangelist today.
    </div>
    <SigninButton
      class="btn bg-green-dark text-white fill-white hover:bg-black"
    >
      Check eligibility via <IconsTweetIcon />
    </SigninButton>
  </div>

  <PageLoader v-if="loading" class="mt-14" />
  <div v-else>
    <div class="container-800 mt-14">
      <div class="p-6 py-8 lg:p-12 bg-white rounded-2xl">
        <ContentsHowDoesItWorksSlider />
      </div>
    </div>

    <div v-if="campaign?.id" class="container-800 my-10 lg:mt-14">
      <div
        class="flex flex-wrap items-center justify-between gap-6 font-semibold"
      >
        <div>
          <div class="text-xl lg:text-2xl">
            This ambassador campaign<br />from Marinade ends in
            <ExpiresIn :date="campaign.dateEnd" />
            .
          </div>
          <SigninButton
            class="mt-6 mb-4 lg:mb-0 lg:mt-4 btn bg-green-dark text-white fill-white hover:bg-black"
          />
        </div>
        <div class="pr-12">
          <MarinadeLandingReward :campaign="campaign" type="extended" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
