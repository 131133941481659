<script setup lang="ts">
import type {
  HomePageCampaignDto,
  InvitationCampaignDto,
} from "~~/_api_generated_";
interface Props {
  campaign: HomePageCampaignDto | InvitationCampaignDto;
  type: string;
}

const props = withDefaults(defineProps<Props>(), {
  type: "default",
});

const campaign = computed(() => {
  return props.campaign;
});

const selectedCurrency = ref("USD");

const campaignCurrencies = computed(() => {
  return prepareCurrencies(campaign.value?.totalBudget);
});

const selectedCurrencyAmount = computed(() => {
  // @ts-expect-error - TODO: fix this later
  return campaignCurrencies.value[selectedCurrency.value];
});

onMounted(() => {
  // @ts-expect-error - TODO: fix this later
  selectedCurrency.value = Object.keys(campaignCurrencies.value).find(
    (i) => i !== "USD"
  );
});
</script>

<template>
  <div v-if="type == 'default'">
    <div class="flex items-center justify-between font-semibold">
      <span class="text-black/50">Your available rewards</span>
      <div class="select-gray-wrapper">
        <select v-model="selectedCurrency" class="select-gray">
          <option
            v-for="currency in Object.keys(campaignCurrencies)"
            :key="currency"
            :value="currency"
          >
            {{ currency }}
          </option>
        </select>
      </div>
    </div>
    <div class="mt-4 text-5xl font-bold">
      <span>{{ selectedCurrencyAmount }}</span>
      <span class="text-2xl uppercase ml-2">{{ selectedCurrency }}</span>
    </div>
  </div>
  <div v-else>
    <div class="">Total Budget</div>
    <div class="flex gap-2 items-end my-2 text-5xl font-bold">
      <span>{{ selectedCurrencyAmount }}</span>
      <span class="text-2xl uppercase">{{ selectedCurrency }}</span>
    </div>
    <div class="text-black/50">
      Approx. {{ prepareCurrencies(campaign.totalBudget)["USD"] }}
    </div>
  </div>
</template>

<style scoped></style>
